.im_mx1px {
    margin-right: 1px !important;
    margin-left: 1px !important;
}
.im_mx2px {
  margin-right: 2px !important;
  margin-left: 2px !important;
}
.im_mb_25{
    margin-bottom: 0.25rem !important;
 }
 .im_mb_50{
    margin-bottom: 0.50rem !important;
 }
 .im_mbn_75{
    margin-bottom: 0.75rem !important;
 }
 .im_mb1{
    margin-bottom: 1rem !important;
 }
 .im_mb1_5{
    margin-bottom: 1.5rem !important;
 }
 .im_mb3{
    margin-bottom: 3rem !important;
 }
 .im_mb4{
    margin-bottom: 4rem !important;
 }
 .im_mb5{
    margin-bottom: 5rem !important;
 }
 .im_mb10{
    margin-bottom: 10rem !important;
 }
.im_pdy1px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}
.im_ps_pt75{
    padding-left: 0.75rem !important;
}
.im_ps_1pt25{
    padding-left: 1.25rem !important;
}
.im_pe_pt75{
    padding-right: 0.75rem !important;
}
.im_pe_1pt25{
    padding-right: 1.25rem !important;
}
.im_ms_1pt75{
    margin-left: 1.75rem !important;
}
.im_p_2pt5{
    padding: 2.5rem !important;
}
.im_p_pt75{
    padding: 0.75rem !important;
}
.im_p_pt15{
    padding: 0.15rem !important;
}
.im_px-3{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.im_px-4{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}
.im_px-5{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
.im_py-4{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}
.im_py-5{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
.im_pt_75{
    padding-top: 0.75rem !important;
}
.im_pt1_25{
    padding-top: 1.25rem !important;
}
.im_pb_75{
    padding-bottom: 0.75rem !important;
}
.im_pb1_25{
    padding-bottom: 1.25rem !important;
}
.im_pb-2{
    padding-bottom: 2rem !important;
}
.im_pb-3{
    padding-bottom: 3rem !important;
}
.im_pb-4{
    padding-bottom: 4rem !important;
}
.im_ps_75{
    padding-left: 0.75rem !important;
}
.im_pe_75{
    padding-right: 0.75rem !important;
}
.im_py_75{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
.im_px_15{
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
}
.im_px_75{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.im_px_1pt2{
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
}
.im_px_2pt5{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.im_px_3pt5{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
}
.im_px_4pt5{
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}
.im_px_5pt5{
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
}
.im_m_pt15{
    margin: 0.15rem !important;
}
.im_me_pt15{
    margin-right: 0.15rem !important;
}
.im_me_pt75{
    margin-right: 0.75rem !important;
}
.im_mx_75{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}
.im_my_15{
    margin-top: 0.15rem !important;
    margin-bottom: 0.15rem !important;
}
.im_my_75{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}
.im_ms10rm{
    margin-left: 10rem;
}
.im_ms13rm{
    margin-left: 13rem;
}
.im_ml_75{
   margin-left: 0.75rem !important;
}
.im_mr_75{
    margin-right: 0.75rem !important;
}
.im_mt_75{
    margin-top: 0.75rem !important;
}
.im_mb_75{
    margin-bottom: 0.75rem !important;
}
.im_mln_25{
    margin-left: -0.25rem !important;
}
.im_mln_50{
    margin-left: -0.50rem !important;
}
.im_mln_75{
    margin-left: -0.75rem !important;
}
.im_mln1{
    margin-left: -1rem !important;
}
.im_mln1_5{
    margin-left: -1.5rem !important;
}
.im_mln3{
    margin-left: -3rem !important;
}
.im_mln4{
    margin-left: -4rem !important;
}
.im_mln5{
    margin-left: -5rem !important;
}
.im_mln10{
    margin-left: -10rem !important;
}
.im_mrn_25{
   margin-right: -0.25rem !important;
}
.im_mrn_50{
   margin-right: -0.50rem !important;
}
.im_mrn_75{
   margin-right: -0.75rem !important;
}
.im_mrn1{
   margin-right: -1rem !important;
}
.im_mrn1_5{
   margin-right: -1.5rem !important;
}
.im_mrn3{
   margin-right: -3rem !important;
}
.im_mrn4{
   margin-right: -4rem !important;
}
.im_mb_15{
    margin-bottom: 0.15rem !important;
}
.im_mrn5{
   margin-right: -5rem !important;
}
.im_mrn10{
   margin-right: -10rem !important;
}
.im_mtn_25{
   margin-top: -0.25rem !important;
}
.im_mtn_50{
   margin-top: -0.50rem !important;
}
.im_mtn_75{
   margin-top: -0.75rem !important;
}
.im_mtn1{
   margin-top: -1rem !important;
}
.im_mtn1_5{
   margin-top: -1.5rem !important;
}
.im_mtn3{
   margin-top: -3rem !important;
}
.im_mtn4{
   margin-top: -4rem !important;
}
.im_mtn5{
   margin-top: -5rem !important;
}
.im_mtn10{
   margin-top: -10rem !important;
}
.im_mbn_25{
   margin-bottom: -0.25rem !important;
}
.im_mbn_50{
   margin-bottom: -0.50rem !important;
}
.im_mbn_75{
   margin-bottom: -0.75rem !important;
}
.im_mbn1{
   margin-bottom: -1rem !important;
}
.im_mbn1_5{
   margin-bottom: -1.5rem !important;
}
.im_mbn3{
   margin-bottom: -3rem !important;
}
.im_mbn4{
   margin-bottom: -4rem !important;
}
.im_mbn5{
   margin-bottom: -5rem !important;
}
.im_mbn10{
   margin-bottom: -10rem !important;
}
.im_mn_25{
   margin: -0.25rem !important;
}
.im_mn_50{
   margin: -0.50rem !important;
}
.im_mn_75{
   margin: -0.75rem !important;
}
.im_mn1{
   margin: -1rem !important;
}
.im_mn1_5{
   margin: -1.5rem !important;
}
.im_mn3{
   margin: -3rem !important;
}
.im_mn4{
   margin: -4rem !important;
}
.im_mn5{
   margin: -5rem !important;
}
.im_mn10{
   margin: -10rem !important;
}
.im-lt_5{
    left: 0.5rem !important;
}
.im-lt1{
    left: 1rem !important;
}
.im-lt2{
    left: 2rem !important;
}
.im-lt3{
   left: 3rem !important;
}
.im-lt4{
    left: 4rem !important;
}
.im-lt5{
    left: 5rem !important;
}
.im-rt_5{
    right: 0.5rem !important;
}
.im-rt1{
    right: 1rem !important;
}
.im-rt2{
    right: 2rem !important;
}
.im-rt3{
   right: 3rem !important;
}
.im-rt4{
    right: 4rem !important;
}
.im-rt5{
    right: 5rem !important;
}
.im-tp_5{
    top: 0.5rem !important;
}
.im-tp1{
    top: 1rem !important;
}
.im-tp2{
    top: 2rem !important;
}
.im-tp3{
   top: 3rem !important;
}
.im-tp4{
    top: 4rem !important;
}
.im-tp5{
   top: 5rem !important;
}
.im-bt_5{
    bottom: 0.5rem !important;
}
.im-bt1{
    margin-top: 1rem !important;
}
.im-bt2{
    bottom: 2rem !important;
}
.im-bt3{
   bottom: 3rem !important;
}
.im-bt4{
    bottom: 4rem !important;
}
.im-bt5{
    bottom: 5rem !important;
}
.im_mb4{
    margin-bottom: 4rem !important;
}
.im_mb5{
    margin-bottom: 5rem !important;
}
.im_mt4{
    margin-top: 4rem !important;
}
.im_mt5{
    margin-top: 5rem !important;
}
.im_ml4{
    margin-left: 4rem !important;
}
.im_ml5{
    margin-left: 5rem !important;
}
.im_mr4{
    margin-right: 4rem !important;
}
.im_mr5{
    margin-right: 5rem !important;
}
.psnFxd{
    position: fixed;
}
.psnSty{
    position: sticky;
}
.psnRel{
    position: relative;
}
.psnAbs{
    position: absolute;
}
.psnAbsRt0{
    position: absolute;
    right: 0;
}
.psnAbsBt0{
    position: absolute;
    bottom: 0;
}
.psnFxdBt0{
    position: fixed;
    bottom: 0;
}
.psnFxBt0{
    position: fixed;
    bottom: 0;
}
.btm120{
    bottom: 120px;
}
.btm80{
    bottom: 80px;
}
.psnAbsHtFlPrt{
    position: absolute;
    height: 100%;
}
.psnAbsWtHtFlPrt{
    position: absolute;
    width: 100%;
    height: 100%;
}
.psnAbsWtHtZxFlPrt{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.vtHrAbsWtHtStEd{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.vtHrAbsWtHtCtEd{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.scrollBarHide {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scrollBarHide::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.ovrflwHide{
    overflow: hidden;
}
.ovrflwAuto{
    overflow: auto;
}
.ovrflwXScroll{
    overflow-x: scroll;
}
.ovrflwYScroll{
    overflow-y: scroll;
}
.hideOverflow{
    overflow-x:hidden;
    text-overflow:ellipsis;
    width:100%;
}
.notificationMsg {
    text-decoration: none
}
.notificationMsg:hover {
    text-decoration: underline !important;
}
@media (max-width: 575.98px) {
    .hide-xs{
        display: none;
    }
    .showBlock-xs{
        display: block;
    }
    .showInBlock-xs{
        display: inline-block;
    }
    .minScr{
        display: block;
    }
    .minScrInBk{
        display: inline-block;
    }
    .maxScr{
        display: none;
    }
    .maxScrInBk{
        display: none;
    }
}
@media (min-width: 576px) {
    .minScr{
        display: none !important;
    }
    .minScrInBk{
        display: none !important;
    }
    .maxScr{
        display: block;
    }
    .maxScrInBk{
        display: inline-block;
    }

}
.pointer{
    cursor: pointer;
}
.nopointer{
    pointer-events: none;
    cursor: default;
}
.pointers{
    cursor: pointer;
    pointer-events: auto;
}
.pointerEvtNone{
    pointer-events: none;
}
.disabledButton{
    pointer-events: none;
    opacity: 0.4;
}
.rdOnly{
    pointer-events: none;
    opacity: 1;
}
.rdClick{
    pointer-events: auto;
}
.vwBlrNtCk{
    opacity: 0.5;
    pointer-events: none;
}
.im_vwClick{
    opacity: 1 !important;
    pointer-events: auto !important;
}
.vt-start, .vt-center, .vt-end, .vtHr-top-left, .vtHr-top-center, .vtHr-top-right, .vtHr-mid-left, .vtHr-mid-center,
.vtHr-mid-right, .vtHr-bottom-left, .vtHr-bottom-center, .vtHr-bottom-right, .hr-start, .hr-center, .hr-end, .vtHr-center{
    position:absolute;
}
.vt-start, .vt-end, .vtHr-top-left, .vtHr-mid-left, .vtHr-bottom-left, .hr-start{
    left: 0;
}
.vt-start, .vtHr-top-left, .vtHr-top-center, .vtHr-top-right, .hr-start, .hr-end{
    top: 0;
}
.vt-center, .vtHr-mid-left, .vtHr-mid-center, .vtHr-mid-right, .vtHr-center{
    top: 50%;
}
.vt-center, .vtHr-mid-left{
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.vt-end, .vtHr-bottom-left, .vtHr-bottom-center, .vtHr-bottom-right{
    bottom: 0;
}
.vtHr-top-center, .vtHr-mid-center, .vtHr-bottom-center, .hr-center, .vtHr-center{
    left: 50%;
}
.vtHr-top-center, .vtHr-bottom-center{
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
.vtHr-top-right, .vtHr-mid-right, .vtHr-bottom-right, .hr-end{
    right: 0;
}
.vtHr-mid-center{
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}
.vtHr-mid-right, .hr-center {
    transform: translateY(-50%);
    -ms-transform: translateY(-50%) ;
}
.vtHr-center{
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.vt-d-flex-start, .vt-d-flex-center, .vt-d-flex-end, .hr-d-flex-start, .hr-d-flex-center, .hr-d-flex-end, .hr-d-flex-btwn, .vt-d-flex-stretch, .vtHr-d-flex-centerBtwn,
.vtHr-d-flex-start, .vtHr-d-flex-center, .vtHr-d-flex-end, .vtHr-d-flex-startCenter, .vtHr-d-flex-startEnd, .vtHr-d-flex-centerStart, .vtHr-d-flex-centerEnd, .vtHr-d-flex-endStart, .vtHr-d-flex-endCenter,
.vtHr-dWp-center, .vt-dWp-start, .vt-dWp-center, .vt-dWp-end, .hr-dWp-start, .hr-dWp-center, .hr-dWp-end, .vt-dWpHt-center, .hr-dWpHt-center, .vtHr-dWpHt-center, .vtHr-dHt-center, .vtHr-dWHt-center, .vt-dHt-center{
   display: -ms-flexbox;
    display: flex;
}
.vt-d-inLn-flex-center{
    display: inline-flex;
}
.vtHr-dWp-center, .vt-dWp-start, .vt-dWp-center, .vt-dWp-end, .hr-dWp-center, .hr-dWp-start, .hr-dWp-end, .vt-dWpHt-center, .hr-dWpHt-center, .vtHr-dWpHt-center, .rowCt, .dFWrap, .modal-header{
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.vt-d-flex-start, .vtHr-d-flex-start, .vtHr-d-flex-startCenter, .vtHr-d-flex-startEnd, .vt-dWp-start, .vt-dHt-start{
    align-items: flex-start;
}
.vt-d-flex-center, .vt-d-inLn-flex-center, .vtHr-d-flex-centerBtwn, .vtHr-d-flex-center, .vtHr-d-flex-centerStart, .vtHr-d-flex-centerEnd, .vtHr-dWp-center,
.vt-dWp-center, .vt-dWpHt-center, .vtHr-dWpHt-center, .vtHr-dHt-center, .vtHr-dWHt-center, .vt-dHt-center{
    align-items: center;
}
.vt-d-flex-end, .vtHr-d-flex-end, .vtHr-d-flex-endStart, .vtHr-d-flex-endCenter, .vt-dWp-end{
    align-items: flex-end;
}
.hr-d-flex-start, .vtHr-d-flex-start, .vtHr-d-flex-centerStart, .vtHr-d-flex-endStart, .hr-dWp-start{
    justify-content: flex-start;
}
.hr-d-flex-center, .vtHr-d-flex-center, .vtHr-d-flex-startCenter, .vtHr-d-flex-endCenter, .vtHr-dWp-center, .hr-dWp-center, .hr-dWpHt-center, .vtHr-dWpHt-center, .vtHr-dHt-center, .vtHr-dWHt-center{
    justify-content: center;
}
.hr-d-flex-end, .vtHr-d-flex-end, .vtHr-d-flex-startEnd, .vtHr-d-flex-centerEnd, .hr-dWp-end{
    justify-content: flex-end;
}
.hr-d-flex-btwn, .vtHr-d-flex-centerBtwn{
    justify-content: space-between;
}
.vt-d-flex-stretch{
    align-items: stretch;
}
.vtHr-im-d-flex-center{
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
}
.vtHr-dWHt-center{
    width: 100%;
}
.vt-dWpHt-center, .hr-dWpHt-center, .vtHr-dWpHt-center, .vt-dHt-start, .vtHr-dHt-center, .vtHr-dWHt-center, .vt-dHt-center{
    height: 100%;
}
.cdSzFx{
    display: flex;
    align-items: stretch;
}
.vt-dWp-htFl-center{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
}
.hr-dWp-htFl-center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
}
.vtHr-dWp-htFl-centerEnd{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.colCnt > * {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
}
.colVtCnt > * {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}
.colHrCnt > * {
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}
.gp_pt75{
    gap: 0.75rem !important;
}
.txtShdw{
    text-shadow: 2px 2px #dee2e6;
}
.txtHfClr{
    background: linear-gradient(to top, #fff 50%, #000 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.tpBf0::before{
    top:0 !important;
}
.tpAf0::after{
    top:0 !important;
}
.btAf0::after{
    bottom:0 !important;
}
.ltAf0::after{
    left:0 !important;
}
.rtAf0::after{
    right:0 !important;
}
.tpAf1::after{
    top: 1px !important;
}
.tpAf2::after{
    top:2px !important;
}
.tpLtRtAf0::after{
    top:0 !important;
    left:0 !important;
    right:0 !important;
}
.tpLtRtBf0::before{
    top:0 !important;
    left:0 !important;
    right:0 !important;
}
.bgTrnsp{
    background: transparent;
}
.bgTrnspClrWht{
    background: transparent;
    color: #fff;
}
.bgTransBkClrWht{
    background: #00000099;
    color: #fff;
}
.bgBk1ClrWht{
    background: #333;
    color: #fff;
}
.lgAdmFmCtrl{
    padding-left: 0;
    padding-right: 0;
    color: #fff !important;
    background: #333 !important;
    border-radius: 0;
    // border-bottom: 1px solid #f8f9fa !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
}
.lgAdmFmCtrl::placeholder{
    color: #6c757d;
}
.lgAdmFmCtrl:focus{
    color: #fff !important;
    background: #333 !important;
    outline: none !important;
    box-shadow: none !important;
    // border-color: none !important;
}
.nohighlight:focus{
    background-color: unset !important;
    outline: none !important;
    box-shadow: none !important;
    // border-color: none !important;
}
.bgTransWhtIcn36{
    width: 36px;
    height: 36px;
    background: #00000099;
    color: #fff;
    border-radius: 50rem;
    padding: 5px;
    margin: 0 5px 0 0;
}
.bgWht{
    background: #fff;
}
.bgBlack{
    background: #000;
}
.bgBk1{
    background: #333;
}
.bgGrey{
    color: #828282;
}
.bgGrey1{
    background: #8f9094;
}
.bgViolet{
    background: #9186de;
}
.bgDynWhtBk{
    background: #fff;
}
.bgDynWhtBk1{
    background: #fff;
}
.chtlstDltBtn:active{
    border-color: #323232;
}
.bdrDynBkWht{
    border-color: #333;
}
.bdrVlt2px{
    border: 2px solid #9186de;
}
.bgNone{
    background: none !important;
}
.bgHrNone:hover{
    background: none !important;
}
.bgHrbtn:hover{
    background: black !important;
}
.noActBdrbtn:active{
    border-color: transparent !important;
}
.pyCryTxt{
    font-style:italic;
}
@media (max-width: 767.98px){
    .aside:not(.open) {
        left: -15rem !important;
    }
    .sesHstLstMb{
        overflow-x: auto;
    }
    .sesHstLstWtMb{
        width: 200%;
    }
}
.frdlstImgSl{
    width: 6px;
    height: 6px;
    background: #00000099;
    border-radius: 50rem;
    padding: 5px;
    margin: 0 5px 0 0;
}
.frdlstImgSlWhtDt{
    width: 3px;
    height: 3px;
    background: #fff;
    border-radius: 50rem;
    padding: 5px;
}
.frdlstClsTmg{
    border-radius: 5px;
    padding: 3px 5px;
    color: #fff !important;
}
.frdlstOffCnsTags{
    height: auto !important;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.fxCnt{
    margin-left: -4.8rem;
}
@media (max-width: 575.98px) {
    .fxCnt{
        margin-left: -1.5rem;
    }
}
.dFlex{
    display: -ms-flexbox;
    display: flex;
}
.im_dNone{
    display: none !important;
}
.dNone{
    display: none;
}
.dBlk{
    display: block;
}
.im_dBlk{
    display: block !important;
}
.dInLnFlex{
    display: inline-flex;
}
.dInLnBlock{
    display: inline-block;
}
.dFWrap{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.cstRwCnt{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.bultStyRm{
    list-style-type: none;
}
.txtWhite{
    color: #fff;
}
.clrWht{
    color: #fff !important;
}
.clrBk{
    color: #000 !important;
}
.clrGrey{
    color: #828282 !important;
}
.clrGy1{
    color: #e7eef8 !important;
}
.clrGy2{
    color: #ccccce !important;
}
.clrLtGn{
    color: #43a047 !important;
}
.clrDynGy2Bk1{
    color: #ccccce !important;
}
.clrDynBk1Gy2{
    color: #333 !important;
}
.clrHrWht:hover{
    color: #fff !important;
}
.clrHrBk:hover{
    color: #000 !important;
}
.bgLtGn{
    background: #43a047 !important;
}
.bgHrGreyWht:hover{
    background: #e9e9ea !important;
    color: #000 !important;
}
.chat-group:not(.chat-group-reply) .chat-messages .chtLink{
    color: #323232 !important;
}
.chat-group.chat-group-reply .chat-messages .chtLink{
    color: #e7eef8 !important;
}
.frdlstDSktpMb{
    width: calc(100% - 120px);
}
@media (max-width: 767.98px) { 
    .frdlstDSktpMb{
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
    }
}
.bdrLftWht1px{
    border-left: 1px solid #fff;
}
.bdrLftWht2px{
    border-left: 2px solid #fff;
}
.bdr1px{
    border: 1px solid #c7c8c9;
}
.bdr2px{
    border: 2px solid #c7c8c9;
}
.chtImgTxBx{
    position: absolute;
    height: 100%;
    z-index: 6;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}
.chtTxBx{
    height: 50px;
    border-radius: 10px;
    padding: 10px 90px 10px 60px;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.chtTxBx::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.chtTxBxSnd{
    position: absolute;
    height: 100%;
    z-index: 6;
    padding: 5px 15px;
    top:0;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.frdlstTagBtn{
    border-radius: 30px;
    font-size: 16px;
    color: #fff !important;
}
.frdlstNty{
    background: #000;
    color: #fff;
    padding: 2px 15px;
    margin: 0 8px 5px 0;
    border-radius: 30px;
}
.frdlstRdDot{
    width: 5px;
    height: 5px;
    background: #ff0000;
}

.frdlstRdStars {
    // --percent: calc(var(--rating) / 5 * 100%);
    position: absolute;
    display: inline-block;
    font-size: 10px;
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
}
.frdlstRdStars::before {
  content: '★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, #ff0000 100%, #fff 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text; 
  -webkit-text-fill-color: transparent;
}
.frdlstTags, .frdlstUnsltTags, .chtStCsn, .chtStCsn1, .frdlstTgSlt, .frdlstImgSl, .frdlstNty, .frdlstRdDo, .ntfBdge, .btnCndBrand1, .roundedIcnCndBrand1, .roundedIcnCndBrand2, .bgTransWhtIcn36,
.chtPrDts, .chtPyPrice, .chtPyBtn, .sesListMn, .sesListMnSlt, .sesListMnLst, .month-scroll, .date-scroll, .year-scroll {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
}
.frdlstTags, .frdlstUnsltTags, .chtStCsn, .frdlstTgSlt{
    border-radius: 10px;
}
.chtStCsn1{
    border-radius: 5px;
}
.frdlstTags{
    cursor: pointer;
    background: #000;
    color: #fff;
    padding: 5px 15px;
    margin: 0 8px 5px 0;
}
.frdlstUnsltTags{
    cursor: pointer;
    border: 2px solid #c7c8c9;
    padding: 5px 15px;
    margin: 0 8px 5px 0;
}
.chtStCsn{
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    color: #fff !important;
    border: 2px solid #c7c8c9;
    padding: 5px 15px;
    margin: 0 8px 5px 0;
}
.chtStCsn1{
    width: 50%;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 15px;
    background: #1c1f27;
    padding: 5px 25px;
    margin: 0;
}
.frdlstTgSlt{
    background: #bfbfbf;
    color: #404040;
    padding: 5px 15px;
    margin: 0 8px 5px 0;   
}
.chtStTxt {
    color: #bfbfbf;
}
.chtStTxt:hover {
    color: #fff;
}
.chtPyUnlmt{
    background: #00b050;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
}
.chtPrDtsLmt{
    background: #242632;
    border: 1px solid #a6a6a6;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
}
.chtPrDts{
    border-radius: 10px;
    background: #a6a6a6;
    color: #242632;
    position: relative;
    z-index: 1;
    font-weight: 700;
}
// .chtPrDtsRd::before{
//     background: #242632;
//     border: 1px solid #a6a6a6;
// }
.chtPyBtn:hover{
    background: #666;
}
.chtPrDtsRd{
    width: 1.4em;
    height: 1.4em;
    background: #242632;
    border: 2px solid #a6a6a6;
}
.chtPrDtsRd:checked{
    width: 1.4em;
    height: 1.4em;
    background: #a6a6a6;
    border: 0px solid #a6a6a6;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='%23242424'/></svg>") !important;
}
.brandRadio {
  position: relative;
}
.brandRadio input[type="radio"] {
  opacity: 0;
}
.brandRadio .brandRdBtnLb1 {
    font-family: "Ubuntu";
    font-size: 15px;
    padding-left: 18px;
    padding-bottom: 2px;
    cursor: pointer;
}
.brandRadio:before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 1px;
  left: 0;
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 1px solid #a6a6a6;
  background: #242632;
}
.brandRadio input[type="radio"] {
  margin: 0px;
}
.brandRadio:checked::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  width: 12px;
  height: 12px;
  background-color: #00b050;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
.brandRadio-default:checked:before {
  border: 1px solid #fff;
}
.chtPyPrice{
    border-radius: 10px;
    background: #fff;
    color: #000;
    position: relative;
    z-index: 1;
    font-weight: 700;
}
.chtPyBtn{
    border-radius: 5px;
    text-decoration: none;
    background: #000;
    color: #fff !important;
    padding: 3px 30px;
    font-weight: 700;
}
.slLstLtRt{
    display: flex;
    max-width: 100%;
    overflow-x: scroll;
    margin: 0 10px;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.slLstLtRt::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.sesListMn{
    border-radius: 10px;
    background: #a6a6a6;
    color: #000;
}
.sesListMnSlt{
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 3px 30px;
}
.sesListMnLst{
    cursor: pointer;
    height: 30px;
    padding: 3px 40px;
}
.sesNxtSes{
    border: 2px solid #414247;
    border-radius: 30px;
    padding: 30px 90px;
}
.sesPlnSlt{
    border: 2px solid #00b050;
}
.sesPlnUnSlt{
    border: 2px solid #323232;
}
.sesPlnLst{
    border-radius: 30px;
    width: 165px;
    // height: 100px;
    flex-direction: column;
    color: #333;
    padding: 3px 30px;
    cursor: pointer;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sesHstTit{
    border-radius: 10px;
    flex-direction: row;
    border: 2px solid #a6a6a6;
    color: #333;
    padding: 3px 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sesHstLst{
    border-radius: 10px;
    border: 2px solid #a6a6a6;
    padding: 3px 5px;
    max-height: 400px;
    // min-height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.sesHstLst::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.sesHstLstItm{
    width: 100%;
    color: #333;
    padding: 3px 0px;
    display: flex;
    flex-wrap: wrap;
}
.sesPlnLstDts{
    display: flex;
    max-width: 96%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.sesPlnLstDts::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.sesPlnSltIcn{
    position: absolute;
    z-index: 1;
}
.btnCndBrand1{
    border-radius: 30px;
    padding: 3px 25px;
}
.roundedIcnCndBrand1{
    border-radius: 50rem;
    padding: 2px;
    width: 20px;
    height: 20px;
    border: 1px solid currentColor;
}
.roundedIcnCndBrand2{
    border-radius: 50rem;
    padding: 2px;
    width: 20px;
    height: 20px;
}
.blackContentShdw, .greyContentShdw {
    border-radius: 2rem;
    width: 100%;
}
.blackContentShdw {
    background: #242731;
    box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
}
.greyContentShdw {
    background: #595959;
    box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
}
.usrNm{
    border-radius: 5px;
    padding: 0 10px;
}
.txt1rm{
    font-size: 1rem;
}
.txt3{
    font-size: 6px;
}
.txt4{
    font-size: 4px;
}
.txt5{
    font-size: 5px;
}
.txt6{
    font-size: 6px;
}
.txt7{
    font-size: 7px;
}
.txt8{
    font-size: 8px !important;
}
.txt9{
    font-size: 9px;
}
.txt10 {
    font-size: 10px;
}
.txt11 {
    font-size: 11px;
}
.txt12 {
    font-size: 12px;
}
.txt13 {
    font-size: 13px;
}
.txt14 {
    font-size: 14px;
}
.txt15 {
    font-size: 15px;
}
.txt16 {
    font-size: 16px;
}
.txt17 {
    font-size: 17px;
}
.txt18 {
    font-size: 18px;
}
.txt19 {
    font-size: 19px;
}
.txt20 {
    font-size: 20px;
}
.txt21 {
    font-size: 21px;
}
.txt22 {
    font-size: 22px;
}
.txt23 {
    font-size: 23px;
}
.txt24 {
    font-size: 24px;
}
.txt25 {
    font-size: 25px;
}
.txt26 {
    font-size: 26px;
}
.txt27 {
    font-size: 27px;
}
.txt28 {
    font-size: 28px;
}
.txt29 {
    font-size: 22px;
}
.txt30 {
    font-size: 30px;
}
.txt32 {
    font-size: 32px;
}
.txt34 {
    font-size: 34px;
}
.txt35 {
    font-size:35px;
}
.txt36 {
    font-size: 36px;
}
.txt38 {
    font-size: 38px;
}
.txt40 {
    font-size:40px;
}
.txt42 {
    font-size:42px;
}
.txt44 {
    font-size:44px;
}
.txt45 {
    font-size:45px;
}
.txt46 {
    font-size:46px;
}
.txt48{
    font-size:48px;
}
.txt50 {
    font-size:50px;
}
.txt60 {
    font-size:60px;
}
.txt70 {
    font-size:70px;
}
.txt80 {
    font-size:80px;
}
.txt90 {
    font-size:90px;
}
.txt100 {
    font-size:100px;
}
@media (max-width: 575.98px) {
    .txt5-xs{
        font-size: 5px !important;
    }
    .txt6-xs{
        font-size: 6px !important;
    }
    .txt7-xs{
        font-size: 7px !important;
    }
    .txt8-xs{
        font-size: 8px !important;
    }
    .txt9-xs{
        font-size: 9px !important;
    }
    .txt10-xs{
        font-size: 10px !important;
    }
    .txt11-xs{
        font-size: 11px !important;
    }
    .txt12-xs{
        font-size: 12px !important;
    }
    .txt13-xs{
        font-size: 13px !important;
    }
    .txt14-xs{
        font-size: 14px !important;
    }
    .txt15-xs{
        font-size: 15px !important;
    }
    .txt16-xs{
        font-size: 16px !important;
    }
    .txt17-xs{
        font-size: 17px !important;
    }
    .txt18-xs{
        font-size: 18px !important;
    }
    .txt19-xs{
        font-size: 19px !important;
    }
    .txt20-xs{
        font-size: 20px !important;
    }
    .txt21-xs{
        font-size: 21px !important;
    }
    .txt22-xs{
        font-size: 22px !important;
    }
    .txt23-xs{
        font-size: 23px !important;
    }
    .txt24-xs{
        font-size: 24px !important;
    }
    .txt25-xs{
        font-size: 25px !important;
    }
    .txt26-xs{
        font-size: 26px !important;
    }
    .txt30-xs{
        font-size: 30px !important;
    }
    .txt35-xs{
        font-size: 35px !important;
    }
    .txt38-xs{
        font-size: 38px !important;
    }
    .txt40-xs{
        font-size: 40px !important;
    }
    .lh0-xs{
        line-height: 0 !important;
    }
    .lh1-xs{
        line-height: 1 !important;
    }
    .lh10px-xs{
        line-height: 10px !important;
    }
    .lh15px-xs{
        line-height: 15px !important;
    }
    .lh20px-xs{
        line-height: 20px !important;
    }
    .lh25px-xs{
        line-height: 25px !important;
    }
    .btnlabel-xs{ 
        display: flex !important;
        align-items: center !important;
        padding:2px 7px !important; 
        font-size:10px !important; 
    }
    .im_p0-xs{
        padding: 0 !important;
    }
}
.ht14{
    height: 14px;
}
.ht16{
    height: 16px;
}
.ht18{
    height: 18px;
}
.ht20{
    height: 20px;
}
.ht25{
    height: 25px;
}
.ht30{
    height: 30px;
}
.ht32{
    height: 32px;
}
.ht35{
    height: 35px;
}
.ht36{
    height: 36px;
}
.ht38{
    height: 38px;
}
.ht40{
    height: 40px;
}
.ht45{
    height: 45px;
}
.ht50{
    height: 50px;
}
.ht55{
    height: 55px;
}
.ht58{
    height: 58px;
}
.ht60{
    height: 60px;
}
.ht65{
    height: 65px;
}
.ht70{
    height: 70px;
}
.ht75{
    height: 75px;
}
.ht80{
    height: 80px !important;
}
.ht85{
    height: 85px;
}
.ht90{
    height: 90px;
}
.ht95{
    height: 95px;
}
.ht100{
    height: 100px;
}
.ht110{
    height: 110px;
}
.ht120{
    height: 120px;
}
.ht122{
    height: 122px;
}
.ht130{
    height: 130px;
}
.ht140{
    height: 140px;
}
.ht150{
    height: 150px;
}
.ht200{
    height: 200px;
}
.ht250{
    height: 250px;
}
.ht300{
    height: 300px;
}
.ht350{
    height: 350px;
}
.ht400{
    height: 400px;
}
.ht425{
    height: 425px !important;
}
.ht450{
    height: 450px;
}
.ht500{
    height: 500px;
}
.ht550{
    height: 550px;
}
.ht600{
    height: 600px;
}
.ht650{
    height: 650px;
}
.ht700{
    height: 700px;
}
.ht750{
    height: 750px;
}
.ht100prt{
    height: 100%;
}
.ht100vh {
    height: 100vh;
}
.htAuto{
    height: auto;
}
.mwt900 {
    max-width: 900px !important;
}
.mwt800 {
    max-width: 800px !important;
}
.mwt700 {
    max-width: 700px !important;
}
.mwt650 {
    max-width: 650px !important;
}
.mwt600 {
    max-width: 600px !important;
}
.mwt550 {
    max-width: 550px !important;
}
.mwt500 {
    max-width: 500px !important;
}
.mwt100 {
    max-width: 100px !important;
}
.ncMwt100 {
    max-width: 100px !important;
}
.ncMwt200 {
    max-width: 200px !important;
}
.wt700 {
    width: 700px !important;
}
.wt600 {
    width: 600px !important;
}
.wt550 {
    width: 550px !important;
}
.wt500 {
    width: 500px !important;
}
.wt475 {
    width: 475px !important;
}
.wt470 {
    width: 470px !important;
}
.wt450 {
    width: 450px !important;
}
.wt435 {
    width: 435px !important;
}
.wt425 {
    width: 425px !important;
}
.wt400 {
    width: 400px !important;
}
.wt375 {
    width: 375px !important;
}
.wt355 {
    width: 355px !important;
}
.wt310 {
    width: 310px !important;
}
.wt290 {
    width: 290px !important;
}
.wt250 {
    width: 250px !important;
}
.wt200 {
    width: 200px !important;
}
.wt100 {
    width: 100px !important;
}
.wt100prt{
    width: 100%;
}
.wt90prt{
    width: 100%;
}
.wtAuto{
    width: auto !important;
}
.w-95{
    width: 95% !important;
}
.w-94{
    width: 94% !important;
}
.w-93{
    width: 93% !important;
}
.w-92{
    width: 92% !important;
}
.w-91{
    width: 91% !important;
}
.w-90{
    width: 90% !important;
}
.w-80{
    width: 80% !important;
}
.w-70{
    width: 70% !important;
}
.w-60{
    width: 60% !important;
}
.w-40{
    width: 40% !important;
}
.w-30{
    width: 30% !important;
}
.w-20{
    width: 20% !important;
}
.w-10{
    width: 10% !important;
}
.mnHt30px{
    min-height: 30px;
}
.wh500{
    width: 500px;
    height: 500px;
}
.wh450{
    width: 450px;
    height: 450px;
}
.wh400{
    width: 400px;
    height: 400px;
}
.wh350{
    width: 350px;
    height: 350px;
}
.wh300{
    width: 300px;
    height: 300px;
}
.wh250{
    width: 250px;
    height: 250px;
}
.wh200{
    width: 200px;
    height: 200px;
}
.wh150{
    width: 150px;
    height: 150px;
}
.wh120{
    width: 120px;
    height: 120px;
}
.wh110{
    width: 110px;
    height: 110px;
}
.wh100{
    width: 100px;
    height: 100px;
}
.wh95{
    width: 95px;
    height: 95px;
}
.wh90{
    width: 90px;
    height: 90px;
}
.wh85{
    width: 85px;
    height: 85px;
}
.wh80{
    width: 80px;
    height: 80px;
}
.wh70{
    width: 70px;
    height: 70px;
}
.wh68{
    width: 68px;
    height: 68px;
}
.wh65{
    width: 65px;
    height: 65px;
}
.wh60{
    width: 60px;
    height: 60px;
}
.wh55 {
    width: 55px;
    height: 55px;
}
.wh50 {
    width: 50px;
    height: 50px;
}
.wh40 {
    width: 40px;
    height: 40px;
}
.wh30 {
    width: 30px;
    height: 30px;
}
.wh25 {
    width: 25px;
    height: 25px;
}
.wh24 {
    width: 24px;
    height: 24px;
}
.wh22 {
    width: 22px;
    height: 22px;
    font-size: 15px !important;
}
.wh20 {
    width: 20px;
    height: 20px;
}
.wh15 {
    width: 15px;
    height: 15px;
}
.wh10 {
    width: 10px;
    height: 10px;
}
.wh5 {
    width: 5px;
    height: 5px;
}
.wh40 {
    width: 40px;
    height: 40px;
}
.wh50 {
    width: 50px;
    height: 50px;
}
.wh300-200 {
    width: 300px;
    height: 200px;
}
.wh17-15 {
    width: 17px;
    height: 15px;
}
.wh36-36 {
    width: 36px !important;
    height: 36px !important;
}
.wh120-50 {
    width: 120px;
    height: 50px;
}
.wh120-40 {
    width: 120px;
    height: 40px;
}
.wh120-45 {
    width: 120px;
    height: 45px;
}
.wh100-50 {
    width: 100px;
    height: 50px;
}
.wh100-40 {
    width: 100px;
    height: 40px;
}
/* minimum screen */
@media (max-width: 575.98px) { 
    .mnMwt100{
        max-width: 100px !important;
    }
}
/* maximum screen */
@media (min-width: 576px) {
    .mxMwt100{
        max-width: 100px !important;
    }
}
.opacityPt1{
    opacity: 0.1;
}
.opacityPt2{
    opacity: 0.2;
}
.opacityPt3{
    opacity: 0.3;
}
.opacityPt4{
    opacity: 0.4;
}
.opacityPt5{
    opacity: 0.5;
}
.opacityPt6{
    opacity: 0.6;
}
.opacityPt7{
    opacity: 0.7;
}
.opacityPt8{
    opacity: 0.8;
}
.opacityPt9{
    opacity: 0.9;
}
.opacity1Pt0{
    opacity: 1.0;
}
.filterPt1{
    filter: brightness(.1);
}
.filterPt2{
    filter: brightness(.2);
}
.filterPt3{
    filter: brightness(.3);
}
.filterPt4{
    filter: brightness(.4);
}
.filterPt5{
    filter: brightness(.5);
}
.filterPt6{
    filter: brightness(.6);
}
.filterPt7{
    filter: brightness(.7);
}
.filterPt8{
    filter: brightness(.8);
}
.filterPt9{
    filter: brightness(.9);
}
.imgPfCntTp{
    object-fit: cover;
    object-position: top;
}
.imgPfCnt, .avatar{
    object-fit: cover;
    object-position: center;
}
.imgPfCntBt{
    object-fit: cover;
    object-position: bottom;
}
.objfitCvr{
    object-fit: cover;
}
.objfitCnt{
    object-fit: contain;
}
.objfitFill{
    object-fit: fill;
}
.objfitScleDwn{
    object-fit: scale-down;
}
.objpsnTp{
    object-position: top;
}
.objpsnBt{
    object-position: bottom;
}
.verticalAlgBsl{
    vertical-align: baseline;
}
.verticalAlgIncl{
    vertical-align: initial;
}
.zIdxRel1{
    position: relative;
    z-index: 1 !important;
}
.zIdxRel2{
    position: relative;
    z-index: 2 !important;
}
.zIdxRel3{
    position: relative;
    z-index: 3 !important;
}
.zIdx1{
    z-index: 1 !important;
}
.zIdx2{
    z-index: 2 !important;
}
.zIdx3{
    z-index: 3 !important;
}
.brd50rm{
    border-radius: 50rem;
}
.brd30{
    border-radius: 30px;
}
.brd15{
    border-radius: 15px;
}
.brd10{
    border-radius: 10px;
}
.brd5{
    border-radius: 5px;
}
.carousel-indicators{
    justify-content: center !important;
    padding-top: 5px;
    padding-bottom: 7px;
}
.aTagLink {
    color: #3DB9D0 !important;
    text-decoration: none;
    // font-size: 15px;
    cursor: pointer;
    flex:1;
    background-image: linear-gradient(to right, #3DB9D0 0, #3DB9D0 100%);
    background-position: 0 1.45em;
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition:.5s;
    padding-bottom: 1.5px;
}
.aTagLink:hover {
    text-decoration: none;
    background-size: 100% 100%;
}
.lgnCdPnlHt{
    max-height: 600px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.lgnCdPnlHt::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.lgnCdPnl{
    position: relative;
    z-index: 1;
}
.lgnBgImg{
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(.6);
}
@media (max-height: 500px) {     
    .lgnBgImg{
        height: 125%;
    }
}
// @media (min-height: 501px) {     
//     .lgnBgImg{
//         height: 100vh;
//     }
// }
.chtlstDltIcn{
    position: relative;
    z-index: 3;
    text-align: right;
    pointer-events: none;
}
.ntfMbScrCnd{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 3px;
}
.ntfBdge{
    width: 18px;
    height: 18px;
    color: #fff;
    background: rgba(77, 105, 250);
    padding: 5px;
    border-radius: 50%;
}
.mnUserPF{
    padding: 0.375rem 0;
}
@media (min-width: 992px) and (min-width: 768px){
    .modern-design .aside:not(.open) + .wrapper {
        padding-left: 6.5rem !important;
    }
}
.ntIm_me-2{
    margin-right: 0.5rem;
}
.im_px-2{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.im_px_15{
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
}
.im_px_75{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.im_px_1pt2{
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
}
.im_px-2pt5{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.clrDynWhtBk{
    color: #fff !important;
}
.clrDynBkWht{
    color: #000 !important;
}
.clrDynBkWht1{
    color: #323232 !important;
}
.clrBgDynWhtBk{
    color: #fff !important;
    background: #000 !important;
}
.clrBgDynBkWht{
    color: #000 !important;
    background: #fff;
}
@media (min-width: 768px) { 
    .im_px_md_75{
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}
.carousel-control-prev, .carousel-control-next{
    pointer-events: none;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
    pointer-events: auto;
}
.btnCloseDfGy .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
    color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
}
// Dark theme
[theme="dark"] .text-muted {
    color: #fff !important;
}
[theme="dark"] .clrDynWhtBk{
    color: #000 !important;
}
[theme="dark"] .clrDynBkWht{
    color: #fff !important;
}
[theme="dark"] .clrDynBkWht1{
    color: #dee2e6 !important;
}
[theme="dark"] .clrBgDynWhtBk{
    color: #000 !important;
    background: #fff !important;
}
[theme="dark"] .clrBgDynBkWht{
    color: #fff !important;
    background: #000;
}
[theme="dark"] .bgDynWhtBk{
    background: #000;
}
[theme="dark"] .bgDynWhtBk1{
    background: #333;
}
[theme="dark"] .bdrDynBkWht{
    border-color: #fff;
}
[theme="dark"] .chtlstDltBtn:active{
    border-color: #dee2e6;
}
// timezone
[theme="dark"] .css-yk16xz-control{
    background: #1f2128;
}
[theme="dark"] .css-1uccc91-singleValue{
    color: #fff !important;
}
[theme="dark"] .css-1pahdxg-control{
    background: #1f2128;
    color: #fff !important;
}
// [theme="dark"] .css-2613qy-menu, [theme="dark"] .css-2613qy-menu > .css-41jt47-MenuList{
//     background: #1f2128;
// }
// [theme="dark"] .timezone-select [class=" css-oymk5x-MenuList"], [theme="dark"] .timezone-select [class=" css-4ljt47-MenuList"]{
//     background: #1f2128;
// }
// [theme="dark"] .timezone-select [class=" css-4ljt47-MenuList"]{
//     background: #1f2128;
// }
[theme="dark"] [class$="-MenuList"]{
    background: #1f2128;
}
[theme="dark"] .css-4ljt47-MenuList, [theme="dark"] .css-oymk5x-MenuList{
    background: #1f2128;
}
// .css-4ljt47-MenuList{
//     background: #1f2128;
// }
[theme="dark"] .css-yt9ioa-option{
    background: #1f2128;
    color: #fff !important;
}
[theme="dark"] .css-1n7v3ny-option{
    background: #323232;
    color: #fff !important;
}
[theme="dark"] .css-9gakcf-option{
    background: #828282;
    color: #fff !important;
}
// component styles
[theme="dark"] .sesPlnLst{
    color: #fff !important;
}
[theme="dark"] .sesHstTit, [theme="dark"] .sesHstLstItm, [theme="dark"] .clrDynBk1Gy2{
    color: #ccccce !important;
}
[theme="dark"] .sesPlnUnSlt{
    border: 2px solid #fafafa;;
}
//ponni custome style
.minicard{
    background-color:#5b5c5f;
    width:300px;
    height: 125px;
    
}

.mainCard{
    width:400px;
    height:225px;
}
.doubleCard{
    padding:5px;
}

// .date-scroll-picker {
//     display: flex;
//     background-color: blue;
//     align-items: center;
//     justify-content: center;
//     font-size: 9px;
//     margin-right: 70px;
//     margin-bottom:3px;
//     flex-wrap: wrap;
//     flex-direction:column;
//   }
  
  .month-scroll,
  .date-scroll,
  .year-scroll{
    border: 1px solid black;
    background-color: black;
    border-radius: 5px;
    padding: 3px;
    overflow: hidden;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: transform 0.2s ease-in-out;
  }

.cardClass{
    max-width:550px;
    height:600px;
}
@media (max-width:782px) {
    .cardClass{
        padding: 0px;
        height: 600px;
        margin: 0px;
    }
    .month-scroll, .date-scroll, .year-scroll{
        font-size: 8px;
    }
    .mainCard, .minicard{
        width:auto;
        height: auto;
    }
    .doubleCard{
        padding:5px;
    }
    .calendar .calendar-container{
        overflow: auto;
    }
    .react-calendar{
        width: auto;
    }
}  
@media screen {
    
}
.ofCvsZid{
    z-index: 1045;
}
.locationCheckBox{
    width: 50px;
}
.calendar{
    position: relative;
}
.cldIcn{
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
}
.react-calendar{
    border: 3px solid #aac0e4;
}